// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import TextSection from 'components/units/unitPages/details/TextSection';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import ListPosts from 'components/blog/listPosts';
import Qa from 'components/Qa';
import Components from 'components/CmsComponents/';
import { HeaderContext } from 'context/header';
import SearchDoctor from 'components/SearchDoctor';
import { createDoctorDataSpecialties } from 'utils/createDoctorDataSpecialties';

import { DoctorContext } from 'context/doctor';

import { setCoordinatorsOrder } from 'utils/setCoordinatorsOrder';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};
  padding-bottom: ${props => props.paddingBottom && '70px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};

    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
      padding-bottom: ${props => props.paddingBottomLap && '70px'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
      padding-bottom: ${props => props.paddingBottomLap && '70px'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: ${props => props.isPaddingTop && '70px 0 0px'};

    @media ${device.laptop} {
      margin-bottom: 0;
      padding: ${props => props.isPaddingTop && '0px 0 30px'};
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    margin: ${props => props.isMarginFullWidth && '0 -1.25rem'};
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    padding-bottom: ${props => props.paddingBottomLap && '70px'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};
    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const RenderPage = (
  page,
  isDesktop,
  pageContext,
  doctorsList,
  doctors,
  specialtyCoordinators,
  location
) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;
  const components = page[0]?.components ?? [];

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);
  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part
          gridColumn="2 / -2"
          width="100%"
          isDisplay="flex"
          notPaddingTop
          paddingBottom
          isPaddingTop
        >
          <TextSection // Sobre nosso núcleo
            markdown={getMarkdown(page, 'ckt1fbp8g0eph0a719y2dzzd3', true)}
            isAbout
            isShowing
            isCDICardio
            isPatologica
            colorText
            isBackground
            isOthersUnits
            containerWidth="50%"
            noMarginRightPage
          />
          <TextSection // Endereços e horários
            markdown={getMarkdown(page, 'ckt1fm1x40i430b29gb5hitkw', true)}
            fragments={getMarkdown(page, 'ckt1fm1x40i430b29gb5hitkw')}
            isAbout
            isShowing
            isPatologica
            marginLeftContainerMobile="3%"
            containerColorEmDesk="#231f20"
            isAddressAndTimes
            isIncreasingContainerWidth
            containerWidth="49%"
          />
        </Part>
        <Part
          gridColumn={'2 / -2'}
          largeTitle
          notPadding
          notLeft
          shortPadding
          borderTop
        >
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckovsinjc140u0b672a142sk1', true)}
            fragments={getMarkdown(page, 'ckovsinjc140u0b672a142sk1')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isPatologica
          />
          <PromptServiceContent
            markdown={getMarkdown(page, 'clpmz0lpld8d20blsf0ykwxp9', true)}
            fragments={getMarkdown(page, 'clpmz0lpld8d20blsf0ykwxp9')}
            padding
            notTitleBold
            facilitiesForYou
            isDropdownDesktop
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            contentFontSizeMob="0.875rem"
            breakLineBr
          />
        </Part>

        <Part gridColumn={'1 / -1'} isNotBorder isMarginFullWidth>
          {components.length >= 1 &&
            components?.map(component => {
              return Components(component);
            })}
        </Part>
        {page?.[0]?.qa && (
          <Part gridColumn={'2 / -2'}>
            <Qa
              title={'Perguntas Frequentes'}
              data={page?.[0]?.qa}
              noBorderBottomLastElement
            />
          </Part>
        )}
        {page?.[0]?.posts?.length > 0 && (
          <Part gridColumn={'2 / -2'} padding borderTop>
            <ListPosts
              title="Blog do Sírio-Libanês"
              postsToShow={3}
              posts={page?.[0]?.posts}
              headerLink={`/${page[0]?.customURLBlog ?? 'blog/'}`}
            />
          </Part>
        )}
      </GridContainer>
    </>
  );
};

const Quadril = ({
  page,
  pageContext,
  doctors,
  specialtyDoctors,
  apiDoctors,
  specialtyCoordinators,
  location,
}) => {
  const [isDesktop, setDesktop] = useState(true);
  const { doctorsList, setDoctorsList, setDoctorsOncology } = useContext(
    DoctorContext
  );
  const formattedDoctors = specialtyDoctors
    .map(doctor =>
      createDoctorDataSpecialties({
        doctorCMS: doctor,
        doctorsAPI: apiDoctors,
      })
    )
    ?.filter(e => e)
    .sort(
      (a, b) =>
        (a.name.trim().toLowerCase() > b.name.trim().toLowerCase() && 1) || -1
    )
    .sort((a, b) => b.employee - a.employee);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  useEffect(() => {
    setCoordinatorsOrder(specialtyCoordinators, formattedDoctors);
    setDoctorsOncology(formattedDoctors);
    setDoctorsList(formattedDoctors);
  }, []);

  const getImage = getMarkdown(page, 'ckt1f9yqg0ek90a71lqtc0nzx', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {RenderPage(
        page,
        isDesktop,
        pageContext,
        doctorsList,
        doctors,
        specialtyCoordinators,
        location
      )}
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          doctorsApi {
            doctors {
              id
              name
              gender
              crm
              email
              phone
              phonecommercial
              specialty
              employee
              estab
              state
            }
          }
          gcms {
            doctors(first: 1000) {
              active
              id
              id_api
            }
            specialty(
              locales: [pt_BR, en]
              where: { id: "ckp2x0tbkv0f40a22lnyrc27m" }
            ) {
              doctor(locales: [pt_BR, en], first: 1000) {
                id
                id_api
                active
                assignment
                name
                crm
                photo {
                  handle
                  url
                  height
                  width
                }
                phonecommercial
                state
                employee
                specialties {
                  id
                  name
                }
              }
              especialidadesCoordenador {
                json
              }
            }
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckt1foh400ikz0d33rgocs85q" }) {
                featuredMarketing(locales: [pt_BR, en]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_HighlightContent {
                    id
                    name
                    type
                    backgroundColorInitial {
                      hex
                    }
                    backgroundColorFinal {
                      hex
                    }
                    title
                    customTitle
                    description
                    images {
                      handle
                      width
                      height
                      url
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                    }
                  }
                  ... on GraphCMS_DoctorAndSpecialtyContent {
                    id
                    name
                    type
                    title
                    subtitle
                    estado
                    images {
                      handle
                      width
                      height
                      url
                    }
                    specialties {
                      id
                      name
                      slug
                      customUrl
                      unity
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                    }
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                qa(locales: pt_BR) {
                  titulo
                  pergunta
                  autor
                  doctors(first: 1000) {
                    active
                    assignment
                    name
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                customURLBlog
                posts {
                  id
                  slugPost
                  title
                  topic
                  author
                  doctors(first: 1000, locales: [pt_BR, en]) {
                    id
                    id_api
                    active
                    assignment
                    name
                  }
                  assetpicker {
                    handle
                    height
                    width
                  }
                  categories {
                    tag
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <Quadril
            page={response.gcms.site.pages}
            doctors={response.gcms.doctors}
            specialtyDoctors={response.gcms.specialty.doctor}
            specialtyCoordinators={
              response.gcms.specialty.especialidadesCoordenador
            }
            apiDoctors={response.doctorsApi.doctors}
            location={props.location}
            {...props}
          />
        );
      }}
    />
  );
};
